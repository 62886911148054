<template>
  <!-- ======= Hero Section ======= -->
  <section id="hero" class="home">
    <div class="container" data-aos="fade-in" data-aos-delay="200">
      <div class="row justify-content-md-center title">
        <div class="col-md-6">
          <li v-show="computedMobile">
            <div class="logo mx-5 px-5 position-relative">
              <a href="/"><img src="assets/img/logo-white.png" alt="CNA Compañia de negocios agropecuarios CNA SA" class=" logo-mobile" /></a>
            </div>
          </li>
          <h1 class=" position-relative">
         Construimos futuro  basados en el respeto del medio ambiente y la vida humana

          </h1>
          
          <a href="#cta" class="btn-get-started scrollto bg-dark text-light"
            >Mas información</a
          >
        </div>
      </div>
    </div>
    <CardButton />
  </section>
  <!-- End Hero -->
</template>
<script>
import CardButton from "./CardButton.vue";

export default {
  name: "Hero",
  components: {
    CardButton,
  },
  data: () => ({
    mobile: false
    }),
  mounted () {
    this.mobile = screen.width <= 768 ? true : false;
  },
  computed: {
    computedMobile () {
      return this.mobile;
    },
  },
  
    
};
</script>
<style lang="css" scoped>

#Hero {
  z-index: 1;
}

.logo {
  top: -50px;
}

.img-fluid {
    max-width: 50%;
    height: auto;
}

.container {
  max-width: 100%;
  position: absolute;
  bottom: 14vh;
}

@media (max-width: 600px) {
  .container {
    max-width: 100%;
    position: absolute;
    bottom: 30vh;
  }
}
</style>


