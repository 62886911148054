<template>
  <div class="navbar-space">
    <nav
      id="navbar"
      class="navbar navbar-top d-flex justify-content-center sticky-top"
      :data-aos="{'fade-in': !computedMobile}"
      :data-aos-delay="{'200':!computedMobile}"
      :class="{
        'navbar-scroll': !view.topOfPage,
        'navbar-top': view.topOfPage,
        'navbar-mobile': computedActiveMenuMobile,
        'navbar-top d-flex justify-content-center sticky-top': !computedMobile
      }"
      :style="{height: heightMenu, top: topMenu}"
    >
      <!-- :style="{ background: gradient }" -->

      <ul>
        <div class="" :class="{'d-flex': !computedMobile}">
          <li class="mr-auto p-2">
            <a 
              class="nav-link scrollto text-white px-2" 
              href="/#cta"
              @click="activeMenu"
            >
              Quiénes Somos
            </a>
          </li>
          <li class="mr-auto p-2">
            <a 
              class="nav-link scrollto text-white px-2" 
              href="/#contact"
              @click="activeMenu"
            >
              Contacto
            </a>
          </li>
          <li v-show="!computedMobile">
            <div class="logo mx-5 px-5" >
              <a href="/"><img src="assets/img/logo-white.png" alt="CNA Compañia de negocios agropecuarios CNA SA" class="img-fluid"  /></a>
            </div>
          </li>
          <li class="ml-auto p-2">
            <a 
              class="nav-link scrollto text-white px-2" 
              href="/#services"
              @click="activeMenu"
            >
              Servicios
            </a>
          </li>
          <li class="ml-auto p-2">
            <a 
              class="nav-link scrollto text-white px-2" 
              href="/#portfolio"
              @click="activeMenu"
            >
              Productos
            </a>
          </li>
        </div>
      </ul>
      <i 
        class="bi bi-list mobile-nav-toggle" 
        :class="{
          'bi-list': !computedActiveMenuMobile,
          'bi-x': computedActiveMenuMobile
        }"
        @click="activeMenu"
        ></i>
    </nav>
  </div>
  <!-- .navbar-->
</template>
<script>
// window.addEventListener("hashchange", function () {
//   console.log(window.scrollY)
//     // window.scrollTo(window.scrollX, window.scrollY - 100);
// });

import GradientColor from "./../utils/GradientColor";

export default {
  name: "NavBar",
  components: {},
  data: () => ({
    view: {
      topOfPage: true,
    },
    menuMobile: false,
    mobile: false,
    heightMenu: '85px',
    topMenu: ''
  }),
  beforeMount() {
    window.addEventListener("scroll", this.handleScroll);
  },
  mounted () {
    this.mobile = screen.width <= 768 ? true : false;
    console.log('this.mobile', this.mobile)
    if (!this.mobile) return
    this.heightMenu = this.mobile ? "45px" : "85px";
    this.topMenu = this.mobile ? '35px' : "";
  },
  computed: {
    gradient() {
      return GradientColor.gradientColors;
    },
    computedMobile () {
      return this.mobile;
    },
    computedActiveMenuMobile () {
      return this.menuMobile;
    },
    // computedHeightMenu () {
    //   console.log('this.heightMenu', this.heightMenu)
    //   return this.heightMenu;
    // },
    // computedTopMenu () {
    //   return this.topMenu;
    // }
  },
  methods: {
    handleScroll() {
      if (window.pageYOffset > 0) {
        if (this.view.topOfPage) this.view.topOfPage = false;
      } else {
        if (!this.view.topOfPage) this.view.topOfPage = true;
      }
    },
    activeMenu () {
      if (!this.mobile) return
      this.menuMobile = !this.menuMobile;
      this.heightMenu = !this.menuMobile ? '45px' : '100%';
      // this.topMenu = !this.mobile ? '35px' : '40px';
      // console.log('this.mobile', this.mobile, this.topMenu)
    }
  },
};
</script>
<style lang="css" scoped>

.navbar-top {
  width: 100%;
  height: 85px;
  position: fixed;
  top: 30px;
  /* background-color: #487634; */
  z-index: 5;
  box-shadow: none;
  transition: 0.4s all ease-out;
}

.navbar.fixed-top.navbar-dark .navbar-nav .nav-link::after {
  background-color: #c0ca33;
}

.nav-link::after {
  content: "";
  position: absolute;
  bottom: 15px;
  left: 30%;
  right: 30%;
  height: 1px;
  background-color: #fff;
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  transition: transform 0.1s ease-in;
}

.navbar-scroll {
  background-color: rgba(0, 0, 0, 0.6);
  height: 80px;
  top: 25px;
  position: fixed;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.8);
  transform: translate3d(0, 0, 0);
  transition: 0.4s all ease-out;
}

.navbar-dark .navbar-nav .nav-link {
  position: relative;
  color: #fff;
  font-size: 1.6rem;
}
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: #fff;
}

.navbar.fixed-top {
  position: fixed;
  -webkit-animation: navbar-animation 0.6s;
  animation: navbar-animation 0.6s;
  background-color: rgba(0, 0, 0, 0.9);
}

/* .nav-link.active {
  color: #5d9419 !important;
} */

@media screen and (max-width: 768px) {
  .navbar-top {
  width: 100%;
  height: 85px;
  position: fixed;
  top: 30px;
  /* background-color: #487634; */
  z-index: 5;
  box-shadow: none;
  transition: 0.4s all ease-out;
}

  .navbar-mobile ul {
    background-color:rgba(0, 0, 0, 0.6);
    /* height: 100% !important; */
  }
  
  .mobile-nav-toggle{
    display: block;
    margin-right: 80%;
  }

  .navbar-brand {
    margin-left: 20px;
  }
  .navbar-nav {
    padding: 0 20px;
    background-color: rgba(0, 0, 0, 0.9);
  }
  .navbar.fixed-top .navbar-nav {
    background: transparent;
  }
}
@media screen and (min-width: 767px) {

  .banner {
    padding: 0 150px;
  }
  .banner h1 {
    font-size: 5rem;
  }
  .banner p {
    font-size: 2rem;
  }
  .nav-link {
    padding: 23px 15px;
  }
  .nav-link::after {
    content: "";
    position: absolute;
    bottom: 25px;
    left: 30%;
    right: 30%;
    height: 2px;
    background-color: #fff;
    -webkit-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0);
    transition: transform 0.1s ease-in;
  }
  .nav-link:hover::after {
    -webkit-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1);
  }
  .dropdown-menu {
    min-width: 200px;
    -webkit-animation: dropdown-animation 0.3s;
    animation: dropdown-animation 0.3s;
    -webkit-transform-origin: top;
    -ms-transform-origin: top;
    transform-origin: top;
  }
}
@-webkit-keyframes navbar-animation {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes navbar-animation {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}
@-webkit-keyframes dropdown-animation {
  0% {
    -webkit-transform: scaleY(0);
    -ms-transform: scaleY(0);
    transform: scaleY(0);
  }
  75% {
    -webkit-transform: scaleY(1.1);
    -ms-transform: scaleY(1.1);
    transform: scaleY(1.1);
  }
  100% {
    -webkit-transform: scaleY(1);
    -ms-transform: scaleY(1);
    transform: scaleY(1);
  }
}
@keyframes dropdown-animation {
  0% {
    -webkit-transform: scaleY(0);
    -ms-transform: scaleY(0);
    transform: scaleY(0);
  }
  75% {
    -webkit-transform: scaleY(1.1);
    -ms-transform: scaleY(1.1);
    transform: scaleY(1.1);
  }
  100% {
    -webkit-transform: scaleY(1);
    -ms-transform: scaleY(1);
    transform: scaleY(1);
  }
}

/* .navbar-space {
  height: 115px;
} */
</style>


