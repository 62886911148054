<template>
    <main id="main">
      <Hero />
      <About />
  <!-- ======= Services Section ======= -->
      <span class="anchor" id="services"></span>
      <section id="" class="services">
        <div class="container content">
           <div class="section-title" data-aos="">
            <h2 style="color:white">Nuestros Servicios</h2>
            <p>
            Nos especializamos en escuchar a nuestros clientes, entender lo que necesitan, asesorar y brindar soluciones a medida.<br>
Contamos con un ágil equipo de trabajo de administración, logística y ventas para dar respuestas inmediatas.<br>
Nos encargamos de todo el operativo logístico a lo largo y ancho del país garantizando la seguridad de los envíos.<br>
Los depósitos donde se almacena nuestra mercadería se encuentran en San Nicolás y Ramallo, siendo los mismos puntos de entregas y despachos.</p>
          </div>
         
        </div>
      </section>
      <!-- End Services Section -->
      <!-- ======= Counts Section ======= -->
      <section id="counts" class="counts">
        <div class="container">
          <div class="row counters">
            <div class="col-lg-6 col-12 text-center">
              <img
                src="assets/img/fertilizantes1.png"
                class="img-fluid py-2"
                alt="Fertilizantes agrícolas"
              />
              <h3 class="text-uppercase">
                Fertilizantes<br />
                agrícolas
              </h3>
            </div>

            <div class="col-lg-6 col-12 text-center">
              <img
                src="assets/img/fertilizantes2.png"
                class="img-fluid py-2"
                alt="Fertilizantes para uso industrial"
              />
              <h3 class="text-uppercase">
                Fertilizantes<br />
                para uso industrial
              </h3>
            </div>
          </div>
        </div>
      </section>
      <!-- End Counts Section -->

      

      <!-- ======= Portfolio Section ======= -->
      <span class="anchor" id="portfolio"></span>
      <section id="" class="portfolio">
        <div class="container">
          <div class="section-title" data-aos="">
            <h2>Nuestros productos</h2>
            <p>
              Con el correr de los años nos hemos posicionado como empresa líder en comercialización de fertilizantes solubles para el agro, brindando paquetes de soluciones a todos nuestros clientes. Hemos sido reconocidos como uno de los mayores y confiables proveedores de productos para las industrias.
            </p>
            <p>
             En CNA SA trabajamos con el objetivo de brindar soluciones sin olvidar la importancia de participar en los sectores que producen alimento, tanto humano como animal, y en uno de los sectores industriales más importantes del país como lo es el petróleo y sus derivados.
            </p>
          </div>

          <div class="row" data-aos="fade-up" data-aos-delay="100">
            <div class="col-lg-12 d-flex justify-content-center">
              <ul id="portfolio-flters">
                <li data-filter="*" class="filter-active">Todos</li>
                <li data-filter=".filter-app">Agro</li>
                <li data-filter=".filter-card">Industriales</li>
              </ul>
            </div>
          </div>

          <div
            class="row portfolio-container"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div
              class="col-lg-4 col-md-6 portfolio-item filter-app filter-card"
            >
              <div class="portfolio-wrap">
                <img
                  src="assets/img/portfolio/CNA-Mop-Cristal.png"
                  class="img-fluid"
                  alt="Urea Ferlilizante Perlado"
                />
                <div class="portfolio-info">
                  <h4>Urea Ferlilizante Perlado</h4>
                
                  <p>Granel, bolsa x 25 kg, Big Bag</p>
                   <p>Fertilizantes Agrícolas</p>
                  <div class="portfolio-links">
                 
                    <a href="assets/img/pdf/UREAPERLADA.pdf" target="_blank" title="Ver detalles"
                      ><i class="bx bx-link"></i
                    ></a>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 portfolio-item filter-app">
              <div class="portfolio-wrap">
                <img
                  src="assets/img/portfolio/CNA-fosfato.png"
                  class="img-fluid"
                  alt="Fosfato Monoamónico Soluble CNA"
                />
                <div class="portfolio-info">
                  <h4>Fosfato Monoamónico Soluble CNA</h4>
                  <p>Bolsa x 25 kg</p>
                  <p>Fertilizantes Agrícolas</p>
                  <div class="portfolio-links">
                    
                    <a
                      href="assets/img/pdf/FOSFATOMONOAMONICOSOLUBLE.pdf" target="_blank"
                      title="Ver detalles"
                      ><i class="bx bx-link"></i
                    ></a>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="col-lg-4 col-md-6 portfolio-item filter-app filter-card"
            >
              <div class="portfolio-wrap">
                <img
                  src="assets/img/portfolio/CNA-ClorurodePotasioSol.png"
                  class="img-fluid"
                  alt="Cloruro de Potasio Soluble CNA"
                />
                <div class="portfolio-info">
                  <h4>Cloruro de Potasio Soluble CNA</h4>
                   <p>Bolsa x 25 kg, Big Bag</p>
                  <p>Fertilizantes Agrícolas</p>
                  <div class="portfolio-links">
                   
                    <a
                      href="assets/img/pdf/CLORURODEPOTASIOSOLUBLE.pdf" target="_blank"
                      title="Ver detalles"
                      ><i class="bx bx-link"></i
                    ></a>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="col-lg-4 col-md-6 portfolio-item filter-app filter-card"
            >
              <div class="portfolio-wrap">
                <img
                  src="assets/img/portfolio/CNA-SulfatodeAmonio.png"
                  class="img-fluid"
                  alt="Sulfato de Amonio CNA"
                />
                <div class="portfolio-info">
                  <h4>Sulfato de Amonio CNA</h4>
                  <p>Bolsa x 25 kg, Big Bag</p>
                  <p>Fertilizante Industrial</p>
                  <div class="portfolio-links">
                    
                    <a
                      href="assets/img/pdf/SULFATODEAMONIOCNA.pdf" target="_blank"
                      title="Ver detalles"
                      ><i class="bx bx-link"></i
                    ></a>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 portfolio-item filter-app">
              <div class="portfolio-wrap">
                <img
                  src="assets/img/portfolio/CNA-SulfatodeMagnesio.png"
                  class="img-fluid"
                  alt="Sulfato de Magnesio Soluble CNA"
                />
                <div class="portfolio-info">
                  <h4>Sulfato de Magnesio Soluble CNA</h4>
                  <p>Bolsa x 25 kg</p>
                  <p>Fertilizante Industrial</p>
                  <div class="portfolio-links">
                    
                    <a
                      href="assets/img/pdf/SULFATODEMAGNESIOSOLUBLECNA.pdf" target="_blank"
                      title="Ver detalles"
                      ><i class="bx bx-link"></i
                    ></a>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="col-lg-4 col-md-6 portfolio-item filter-app filter-card"
            >
              <div class="portfolio-wrap">
                <img
                  src="assets/img/portfolio/CNA-sulfato-de-potasio.png"
                  class="img-fluid"
                  alt="Sulfato de Potasio (Cristalizado) CNA"
                />
                <div class="portfolio-info">
                  <h4>Sulfato de Potasio (Cristalizado) CNA</h4>
                  <p>Bolsa x 25 kg, Big Bag</p>
                  <p>Fertilizantes Agrícolas</p>
                  <div class="portfolio-links">
                   
                    <a
                      href="assets/img/pdf/SULFATODEPOTASIOCristal.pdf" target="_blank"
                      title="Ver detalles"
                      ><i class="bx bx-link"></i
                    ></a>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 portfolio-item filter-card">
              <div class="portfolio-wrap">
                <img
                  src="assets/img/portfolio/Urea_perlada_grado_automotor.png"
                  class="img-fluid"
                  alt="Urea perlada grado automotor CNA"
                />
                <div class="portfolio-info">
                  <h4>Urea perlada grado automotor CNA</h4>
                  <p>Big Bag</p>
                  <p>Fertilizante Industrial</p>
                  <div class="portfolio-links">
                   
                     <a
                      href="assets/img/pdf/UREAPERLADAAUTOMOTOR1000kg.pdf" target="_blank" title="Ver detalles"
                      ><i class="bx bx-link"></i
                    ></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 portfolio-item filter-app">
              <div class="portfolio-wrap">
                <img
                  src="assets/img/portfolio/CNA-NitratodePotasio.png"
                  class="img-fluid"
                  alt="Nitrato de Potasio Soluble CNA"
                />
                <div class="portfolio-info">
                  <h4>Nitrato de Potasio Soluble CNA</h4>
                  <p>Bolsa x 25 kg</p>
                  <p>Fertilizantes Agrícolas</p>
                  <div class="portfolio-links">
                   
                    <a
                      href="assets/img/pdf/Etiqueta_CNA_SNOP.pdf" target="_blank"
                      title="Ver detalles"
                      ><i class="bx bx-link"></i
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- End Portfolio Section -->

      <!-- ======= Counts Section ======= -->
      <span class="anchor" id="cta"></span>
      <section id="" class="cta">
        <div class="container" data-aos="fade-in" data-aos-delay="200">
          <div class="row counters">
            <div class="col-lg-6 col-12 box">
              <h3>Sobre nosotros</h3>
              <h6 style="color:white">
                "Creemos en las buenas prácticas agrícolas y responsables en
                cada uno de los negocios pactados, asumiendo nuestro rol y
                comprometiéndonos al máximo para lograr la mayor eficiencia
                posible."
              </h6>
              <a href="/quienes-somos" class="btn-link">Leer mas...</a>
            </div>
          </div>
        </div>
      </section>
      <!-- End Counts Section -->
     

      <!-- ======= Contact Section ======= -->
      <span class="anchor" id="contact"></span>
      <section id="" class="contact" >
        <div class="container">
          <div class="row">
            <div class="col-lg-4 " data-aos="fade-right">
              <div class="section-title">
                <h2>Contacto</h2>
                
                <p>
                 Sus preguntas y comentarios son importantes para nosotros.
                </p>
                <br><br>
                <p>
                <i class="bi bi-geo-alt-fill"></i> Arcos 2215 piso 3 oficina
                301,<br />
                Buenos Aires, Argentina (C1428AFI)
                <br />
               
                <strong><i class="bi bi-envelope-fill"></i> Email:</strong> 
                 <a href="mailto:comercial@cna-sa.com">comercial@cna-sa.com</a><br />
              </p>
              </div>
            </div>

            <div class="col-lg-8" data-aos="fade-up" data-aos-delay="100">
            
              <form
                role="form"
                class="php-email-form mt-4"
                @submit.prevent="onSubmit"
              >
                <div class="row">
                  <div class="col-md-6 form-group">
                    <input
                      type="text"
                      name="name"
                      class="form-control"
                      id="name"
                      placeholder="Su nombre "
                      required
                      v-model="from_name"
                    />
                  </div>
                  <div class="col-md-6 form-group mt-3 mt-md-0">
                    <input
                      type="email"
                      class="form-control"
                      name="email"
                      id="email"
                      placeholder="Su Email"
                      required
                      v-model="email"
                    />
                  </div>
                </div>
                 <div class="form-group mt-3">
                  <input
                    type="text"
                    class="form-control"
                    name="phone"
                    id="phone"
                    placeholder="Teléfono"
                    required
                    v-model="phone"
                  />
                </div>
                <div class="form-group mt-3">
                  <input
                    type="text"
                    class="form-control"
                    name="subject"
                    id="subject"
                    placeholder="Asunto"
                    required
                    v-model="subject"
                  />
                </div>
                <div class="form-group mt-3">
                  <textarea
                    class="form-control"
                    name="text_message"
                    rows="5"
                    placeholder="Mensaje"
                    required
                    v-model="text_message"
                  ></textarea>
                </div>
                <div class="my-3">
                  <div class="loading" style="display: block" v-show="computedSendMail">Enviando el correo...</div>
                  <div class="error-message"></div>
                  <div class="d-flex justify-content-center my-3" v-if="enableAlert">
                  <div
                    class="col-6 alert alert-success"
                    role="alert">
                    Su correo ha sido registrado correctamente.
                  </div>
                </div>
                </div>
                <div class="text-center">
                  <button type="submit" :disabled="computedDisableBtn">Enviar</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <!-- End Contact Section -->

    </main>
    <!-- End #main -->
</template>
<script>


import Hero from "./../components/Hero.vue";
import About from "./../components/About.vue";

import emailjs from "emailjs-com";

export default {
  name: "Home",
  components: {
    Hero,
    About,
  },
  data: () => ({
    from_name: "",
    email: "",
    subject: "",
    phone: "",
    text_message: "",
    viewSuccess: false,
    disableBtn: false,
    sendmail: false
  }),
  computed: {
    enableAlert: function () {
      return this.viewSuccess
    },
    computedDisableBtn: function () {
      return this.disableBtn
    },
    computedSendMail: function () {
      return this.sendmail
    }
  },
    methods: {
    async onSubmit(e) {
      try {
        this.disableBtn = true;
        this.sendmail = true;
        const res = await emailjs.sendForm(
          "service_85kc4or",
          "template_c8fity9",
          e.target,
          "user_uCZ9UjcOHiETvfiupf3B8",
          {
            from_name: this.from_name,
            email: this.email,
            phone: this.phone,
            subject: '<strong> Asunto: </strong>' + ' ' + this.subject + ' | <strong> Mensaje: </strong>' + this.message
          }
        );
        this.viewSuccess = res.text === 'OK';
        this.sendmail = false;
        console.log(res)
      } catch (error) {
        this.disableBtn = false;
        console.log({ error });
      }
      this.email = "";
    },
  },
};
</script>
<style scoped>
.anchor{
  display: block;
  height: 110px; /*same height as header*/
  margin-top: -110px; /*same height as header*/
  visibility: hidden;
}
</style>