var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"navbar-space"},[_c('nav',{staticClass:"navbar navbar-top d-flex justify-content-center sticky-top",class:{
      'navbar-scroll': !_vm.view.topOfPage,
      'navbar-top': _vm.view.topOfPage,
      'navbar-mobile': _vm.computedActiveMenuMobile,
      'navbar-top d-flex justify-content-center sticky-top': !_vm.computedMobile
    },style:({height: _vm.heightMenu, top: _vm.topMenu}),attrs:{"id":"navbar","data-aos":{'fade-in': !_vm.computedMobile},"data-aos-delay":{'200':!_vm.computedMobile}}},[_c('ul',[_c('div',{class:{'d-flex': !_vm.computedMobile}},[_c('li',{staticClass:"mr-auto p-2"},[_c('a',{staticClass:"nav-link scrollto text-white px-2",attrs:{"href":"/#cta"},on:{"click":_vm.activeMenu}},[_vm._v(" Quiénes Somos ")])]),_c('li',{staticClass:"mr-auto p-2"},[_c('a',{staticClass:"nav-link scrollto text-white px-2",attrs:{"href":"/#contact"},on:{"click":_vm.activeMenu}},[_vm._v(" Contacto ")])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(!_vm.computedMobile),expression:"!computedMobile"}]},[_vm._m(0)]),_c('li',{staticClass:"ml-auto p-2"},[_c('a',{staticClass:"nav-link scrollto text-white px-2",attrs:{"href":"/#services"},on:{"click":_vm.activeMenu}},[_vm._v(" Servicios ")])]),_c('li',{staticClass:"ml-auto p-2"},[_c('a',{staticClass:"nav-link scrollto text-white px-2",attrs:{"href":"/#portfolio"},on:{"click":_vm.activeMenu}},[_vm._v(" Productos ")])])])]),_c('i',{staticClass:"bi bi-list mobile-nav-toggle",class:{
        'bi-list': !_vm.computedActiveMenuMobile,
        'bi-x': _vm.computedActiveMenuMobile
      },on:{"click":_vm.activeMenu}})])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"logo mx-5 px-5"},[_c('a',{attrs:{"href":"/"}},[_c('img',{staticClass:"img-fluid",attrs:{"src":"assets/img/logo-white.png","alt":"CNA Compañia de negocios agropecuarios CNA SA"}})])])}]

export { render, staticRenderFns }