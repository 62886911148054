const gradient = () => {
  const colorsGradient = [
    { id: 0, hex: "#5d9419", disabled: false },
    { id: 1, hex: "#a7d224", disabled: false },
    // { id: 1, hex: "#91ad3c", disabled: false },
    
  ];

  let colors = "linear-gradient(45deg";
  colorsGradient.forEach(function(e) {
    colors += "," + e.hex;
  });
  colors += ")";
  console.log("colors", colors);
  return colors;
};

const gradientColors = gradient();

export default {
  gradientColors,
};

// export default {
//   gradient
// }
