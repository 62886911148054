<template>
  <!-- ======= About Section ======= -->
  <section id="about" class="about">
    <div class="container mt-5">
      <div class="row content">
        <div class="col-lg-12" data-aos="fade-right" data-aos-delay="100">
        
          <div class="row">
            <div class="col-md-9 mx-auto">
              <p class="lead">
                Somos una empresa Argentina con una reconocida trayectoria en el mercado, enfocada en brindar soluciones a las necesidades de nuestros clientes.   <br> <br>

Nos dedicamos a la importación, distribución y venta de productos fertilizantes, destinados a las industrias agrícolas, químicas y petroleras.  <br> <br>

Trabajamos en el desarrollo continuo de una red comercial que recorre las diferentes zonas productivas, lo que nos permite estar cerca de nuestros clientes y contribuir al éxito de sus negocios.     
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End About Section -->
</template>
<script>
export default {
  name: "About",
  components: {},
  data: () => ({}),
  computed: {},
};
</script>
<style lang="css" scoped>
h2 {
  color: #5d9419;
}
#about {
  overflow: visible !important;
}

.section {
  overflow: visible !important;
}
</style>


