<template>
  <div>
    <!-- ======= Footer ======= -->
    <footer id="footer">
      <div class="footer-top">
        <div class="container">
          <div class="row footer-newsletter">
            <div class="d-flex justify-content-center">
              <h3>Subscribite a nuestro Newsletter</h3>
            </div>
            <div class="d-flex justify-content-center">
              <form
                action=""
                method="post"
                class="col-md-6 col-12"
                @submit.prevent="onSubmit"
              >
                <input type="email" name="email" v-model="email" required />
                <input type="submit" value="Subscribirse" />
              </form>
            </div>
            <div class="d-flex justify-content-center my-3" v-if="enableAlert">
              <div
                class="col-6 alert alert-success"
                role="alert">
                Su correo ha sido registrado correctamente.
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-6 footer-contact">
              <br />
              <br />
              <img
                src="assets/img/logo.png"
                alt="CNA"
                width="90px"
                class="img-fluid"
              />
              <p>
                Somos una empresa Argentina con una reconocida trayectoria en el mercado, enfocada en brindar soluciones adaptándonos a las necesidades de nuestros clientes. Nos dedicamos a la importación, distribución y venta de productos fertilizantes, destinados a las industrias agrícolas, químicas y petroleras. Trabajamos en el desarrollo continuo de una red comercial que recorre las diferentes zonas productivas, lo que nos permite estar cerca del productor y contribuir al éxito de sus negocios.
              </p>
            </div>
           
            <div class="col-lg-2 col-md-6 footer-links">
              <br />
              <br />
              <br />
              <br />
              <h4>Links Útiles</h4>
              <ul>
                <li>
                  <i class="bx bx-chevron-right"></i>
                  <a href="#">Quiénes Somos</a>
                </li>
                <li>
                  <i class="bx bx-chevron-right"></i> <a href="#">Contacto</a>
                </li>
                <li>
                  <i class="bx bx-chevron-right"></i> <a href="#">Servicios</a>
                </li>
                <li>
                  <i class="bx bx-chevron-right"></i> <a href="#">Productos</a>
                </li>
              </ul>
            </div>

            <div class="col-lg-4 col-md-6 footer-contact">
              <br />
              <br />
              <br />
              <br />
              <h4>Contáctenos</h4>
              <p>
                <i class="bi bi-geo-alt-fill"></i> Arcos 2215 piso 3 oficina
                301,<br />
                Buenos Aires, Argentina (C1428AFI)
                <br />
            
                <strong><i class="bi bi-envelope-fill"></i> Email: </strong> 
               <a href="mailto:comercial@cna-sa.com">comercial@cna-sa.com</a> <br />
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="container d-md-flex py-4">
        <div class="me-md-auto text-center text-md-start">
          <div class="copyright">
            &copy; Copyright <strong><span>CNA</span></strong
            >. Todos los derechos reservados
          </div>
          <div class="credits">
            <!-- All the links in the footer should remain intact. -->
            <!-- You can delete the links only if you purchased the pro version. -->
            <!-- Licensing information: https://bootstrapmade.com/license/ -->
            <!-- Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/bethany-free-onepage-bootstrap-theme/ -->
          </div>
        </div>
        <div class="text-center text-md-right pt-3 pt-md-0">
         <p>            Designed by <a href="http://mima-tech.com.ar/">MimaTech</a>

         </p>        </div>
      </div>
    </footer>
    <!-- End Footer -->
  </div>
</template>
<script>
import emailjs from "emailjs-com";

export default {
  name: "Footer",
  data: () => ({
    email: "",
    viewSuccess: false
  }),
  computed: {
    enableAlert: function () {
      return this.viewSuccess
    }
  },
  methods: {
    onSubmit(e) {
      try {
        emailjs.sendForm(
          "service_85kc4or",
          "template_nlag15p",
          e.target,
          "user_uCZ9UjcOHiETvfiupf3B8",
          {
            email: this.email,
          }
        );
        this.viewSuccess = true;
      } catch (error) {
        console.log({ error });
      }
      this.email = "";
    },
  },
};
</script>
<style lang="">
</style>