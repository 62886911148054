<template>
  <div>
    <!-- ======= Header ======= -->
    <header
      id="header"
      class="fixed-top"
      :class="{'header-scrolled': !mobile}"
      :style="{ background: gradient }"
    >
      <!-- <div class="container"> -->
        <div class="header-container bg-transparent text-white">
          <div class="row my-2">
            <div class="col-6 initialism header-text">
              <strong> Compañia de negocios agropecuarios CNA </strong>
            </div>
            <div class="col-6 small header-text">
              <strong><a href="https://api.whatsapp.com/send?phone=5491156192589" target="_blank"><i class="bi bi-whatsapp"></i></a>  /  <a href="mailto:comercial@cna-sa.com">comercial@cna-sa.com</a></strong>
            </div>
          </div>
        </div>
        <!-- End Header Container -->
      <!-- </div> -->
    </header>
    <!-- End Header -->
    <div class="btn-whatsapp">
      <a href="https://api.whatsapp.com/send?phone=5491156192589" target="_blank">
        <img src="http://s2.accesoperu.com/logos/btn_whatsapp.png" alt="" height="50">
      </a>
    </div>
  </div>
</template>
<script>
import GradientColor from "./../utils/GradientColor";

export default {
  name: "Header",
  components: {},
  data: () => ({
    mobile: false,
  }),
  mounted () {
    this.mobile = screen.width <= 768 ? true : false;
  },
  computed: {
    gradient() {
      return GradientColor.gradientColors;
    },
  },
};
</script>
<style lang="css">
.header-text {
  font-size: 0.8rem;
}

@media (max-width: 600px) {
  .header-text {
    font-size: 0.55rem;
  }
}
</style>


