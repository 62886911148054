<template>
  <!-- ======= Card Buttom Section ======= -->
  <section id="cardButton" class="cardButtom"  >
    <div class="row">
      
      <div class="col-xl-6 col-sm-12" >
        <div class="">
          <div class="row container-buttom justify-content-center title" style=" margin:0">
            <div
              class="col-xl-6 col-sm-12 d-flex align-items-center rounded box-button px-2 mx-1"
              data-aos="zoom-in"
              data-aos-delay="100"
              :style="{ background: gradient }"
            >
              <div class="my-xl-1 mx-auto p-3">
                <div class="container-text">
                  <h4 class="initialism text-light"><a href="#cta">QUIéNES SOMOS</a></h4>
                </div>
              </div>
            </div>

            <div
              class="col-xl-6 col-sm-12 d-flex align-items-center rounded box-button px-2 mx-1"
              data-aos="zoom-in"
              data-aos-delay="100"
              :style="{ background: gradient }"
            >
              <div class="my-xl-2 mx-auto p-3">
                <div class="container-text">
                  <h4 class="initialism text-light"><a href="#services">NUESTROS SERVICIOS</a></h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Card Buttom Section -->
</template>
<script>
import GradientColor from "./../utils/GradientColor";

export default {
  name: "CardButton",
  props: [],
  components: {},
  data: () => ({}),
  computed: {
    gradient() {
      return GradientColor.gradientColors;
    },
  },
};
</script>
<style lang="css" scoped>
#cardButton {
  padding: 0;
  overflow: visible;
}

h4 {
  margin-bottom: 0;
}



.rounded {
  border-radius: 0,2rem !important;
}

.container-buttom {
  position: absolute;
  top: 70vh;
  width: 100%;
}

.container-text {
  width: 130px;
}

.box-button {
  font-size: 1.8rem;
  width: 400px;
  height: 120px;
}

@media (max-width: 600px) {
  .container-buttom {
    margin: auto;
    top: 80vh;
  }
  .box-button {
    font-size: 1.5rem;
    width: 300px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 2px;
    margin-top: 2px;
  }

}

@media (max-width: 800px) {
  .container-buttom {
    margin: auto;
    top: 70vh;
  }

  .box-button {
    font-size: 1.5rem;
    width: 300px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 2px;
    margin-top: 2px;
  }
}

</style>


